@import "common.scss";

$screenshot_width: 454px;
$screenshot_height: 278px;
$screenshot_height_collapsed: 128px;

#screenshot-container {
    height: $screenshot_height;
    transition: transform 0.5s, opacity 1s;
    position: absolute;
}

// this needs to be enabled on webkit but disabled on edge
.jpeg2000 #screenshot-container {
    -webkit-transform-style: preserve-3d; // otherwise glitches
}

#screenshot-container.hidden {
    opacity: 0;
    transform: translateY(-10px);
    display: block !important;
}

#screenshot {
    position: absolute;
    width: $screenshot_width;
    height: $screenshot_height;
    background-image: url("/images/screenshot.png");
    background-repeat: no-repeat;
    background-position: top;
    border-radius: 8px;
    border: 2px solid rgba(255, 255, 255, 1);
    opacity: 1;
    //box-shadow: 0px 0px 1px black, 0px 0px 10px rgba(0,0,0,0.3);
    @include hidpi {
        background-image: url("/images/screenshot@2x.png");
        width: $screenshot_width * 2;
        height: $screenshot_height * 2;
        border-radius: 15px;
        transform: scale(0.5, 0.5) translate(-50%, -50%);
        border: 5px solid rgba(255, 255, 255, 1);
    }
    transition: height 0.5s linear;
}

.jpeg2000 #screenshot {
    // glitches on safari
    -webkit-backface-visibility: hidden;
}

#screenshot.collapsed {
    height: $screenshot_height_collapsed;
    @include hidpi {
        height: $screenshot_height_collapsed * 2;
    }
}

#screenshot.shadow {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    @include hidpi {
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    }
}

$scrubbing_preview_width: 426px;
$scrubbing_preview_height: 193px;

#scrubbing-preview-container {
    position: absolute;
    width: $scrubbing_preview_width;
    height: $scrubbing_preview_height;
    background-image: url(../images/popover-shadow.png);
    top: 135px;
    left: -25px;

    @include hidpi {
        background-image: url(../images/popover-shadow@2x.png);
        background-size: 100% 100%;
    }
}

#scrubbing-preview-container.animated-opacity {
    transition: opacity 1s;
}

#scrubbing-preview {
    width: $scrubbing_preview_width;
    height: $scrubbing_preview_height;
    @include hidpi {
        background-size: auto 100%;
    }
}

#scrubbing-preview-container.hidden {
    visibility: shown;
    display: block !important;
    // necessary for IE to preload image
    opacity: 0.05;
    height: 1px !important;
    overflow: hidden;
}

#scrubbing-preview-container {
    display: none !important;
}

.jpeg2000 #scrubbing-preview-container,
.webp #scrubbing-preview-container,
.jpegxr #scrubbing-preview-container {
    display: block !important;
}

.jpeg2000 #scrubbing-preview {
    background-image: url("../images/scrubbing-preview.jp2");
    @include hidpi {
        background-image: url("../images/scrubbing-preview@2x.jp2");
    }
}

.webp #scrubbing-preview {
    background-image: url("../images/scrubbing-preview.webp");
    @include hidpi {
        background-image: url("../images/scrubbing-preview@2x.webp");
    }
}

.jpegxr #scrubbing-preview {
    background-image: url("../images/scrubbing-preview.wdp");
    @include hidpi {
        background-image: url("../images/scrubbing-preview@2x.wdp");
    }
}
