$break-small-legacy: 762px;

@mixin small_legacy {
    @media screen and (max-width: $break-small-legacy) {
        @content;
    }
}

@mixin not_small {
    @media screen and (min-width: ($break-small-legacy + 1)) {
        @content;
    }
}

$break-medium: 762px;
$break-small: 560px;
$break-tiny: 460px;

@mixin tiny {
    @media screen and (max-width: $break-tiny) {
        @content;
    }
}


@mixin small {
    @media screen and (max-width: $break-small) {
        @content;
    }
}

@mixin medium {
    @media screen and (max-width: $break-medium) {
        @content;
    }
}

// @mixin small_or_medium {
//     @media screen and (min-width: $break-medium), screen and (max-width: $break-small) {
//         @content;
//     }
// }

@mixin hidpi {
    @media only screen and (-webkit-min-device-pixel-ratio: 1.3),
        only screen and (-o-min-device-pixel-ratio: 13/10),
        only screen and (min-resolution: 120dpi) {
        @content;
    }
}
