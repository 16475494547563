@import "common.scss";

body {
    font-family: Muli, sans-serif;
    font-weight: 300;
    font-size: 17px;
}

body.mac .win32,
body.mac .win64,
body.win32 .mac,
body.win32 .win64,
body.win64 .mac,
body.win64 .win32 {
    display: none !important;
}

body.d_mac .d_win {
    display: none;
}

body.d_win .d_mac {
    display: none;
}

strong {
    font-weight: 800;
}

em {
    font-weight: 400;
}

div.container {
    // font: 400 15px/24px Oxygen;
    max-width: 48rem;
    line-height: 160%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 3rem;
    padding-right: 3rem;
    box-sizing: content-box;
    overflow: hidden; // prevent collapsing margins
}

.content {
    background-color: white;
}

.content.faq {
    padding-bottom: 2em;
}

div.downloads.beta {
    background-color: #f7f7f7;
    padding-top: 2rem;
    border-top: none;

    h2 {
        color: rgb(101, 202, 42);
    }
}

@mixin badge {
    transform: scale(0.9, 0.9);
    display: inline-block;
    padding: 1px 10px;
    border-radius: 18px;
    text-transform: uppercase;
    font-size: 85%;
    font-weight: 600;
    font-style: italic;
}

.home {
    // Make sure this covers scrubbing preview;
    > div:first-of-type {
        transform: translateZ(0); // force layer otherwise safari glitches
        background-color: white;
        z-index: 1;
        position: relative;
    }
}

.downloads,
.home {
    h2 {
        margin-top: 4rem;
        margin-bottom: 3rem;
        text-align: center;
        font-style: italic;
        font-weight: normal;
        line-height: 130%;
        // font-weight: 200;
        font-size: 200%;
        color: #ba6bca;
    }

    .store-links {
        a {
            display: inline-block;
        }

        img {
            width: 260px;
        }
    }

    span.examples {
        text-decoration: underline;
        color: #ba6bca;
        display: inline-block;

        div.what {
            // right: 100%;
            // top: 50%;
            left: 50%;
            transform: translate(-50%, 0);
            font-weight: normal;
            text-decoration: none;
            position: absolute;
            pointer-events: none;
        }

        div.what > div {
            background-color: white;
            width: 500px;
            font-size: 90%;
            background-color: #444;
            color: #fff;
            border-radius: 11px;
            padding: 0.5rem 1.5rem;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
            margin-top: 1rem;
            opacity: 0;
            transform: translate(0, 10px);
            transition: opacity 0.5s, transform 0.5s;
        }

        ul {
            padding-left: 30px;
        }

        .beta {
            transform: scale(0.9, 0.9);
        }
    }

    span.examples:hover {
        text-decoration: none;
        div.what {
            pointer-events: all;

            > div {
                opacity: 1;
                transform: none;
            }
        }
    }

    p,
    div.first {
        overflow: hidden;
        @include small {
            overflow: visible;
        }
    }

    .darker {
        background-color: #f1f4f8;
        border-radius: 10px;
        padding-bottom: 3rem;
        margin-top: 1rem;
    }

    .darker h2 {
        margin-top: 3rem;
    }

    img {
        object-fit: scale-down;
    }

    img.right {
        float: right;
        margin-left: 1.5rem;

        @include tiny {
            float: none;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1rem;
        }
    }

    img.left {
        float: left;
        margin-right: 1.5rem;

        @include tiny {
            float: none;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1rem;
        }
    }

    img.large {
        @include small {
            float: none;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1rem;
        }
    }

    div.subtitle {
        padding: 1rem;
        background-color: black;
        color: white;
        box-sizing: border-box;
        border-radius: 8px;
        text-align: center;
        font-family: Sans-Serif;
        font-size: 27px;
        line-height: 130%;
        font-weight: 400;
    }

    img.shadow {
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.4);
        border-radius: 6px;
    }

    div.middle,
    img.middle {
        margin-top: 2rem;
        margin-left: auto;
        margin-right: auto;
        display: block;
        max-width: 100%;
    }

    .beta {
        @include badge;
        background-color: rgb(101, 202, 42);
        color: white;
    }

    a.beta {
        text-decoration: none;
    }

    a.beta:hover {
        text-decoration: underline;
    }

    .stable {
        @include badge;
        background-color: #ba6bca;
        color: white;
    }

    h2 .beta,
    h2 .stable {
        margin-left: 0.1em;
        padding: 5px 15px;
        line-height: 130%;
        font-size: 70%;
        position: relative;
        top: -3px; // don't want baseline alignment here
    }
}

.support {

    .container {
        max-width: 44rem;
    }

    .footer {
        margin-top: 1rem;
    }

    font-size: 16px;

    .header {
        > a {
            width: 48rem;
            margin-left: auto;
            margin-right: auto;

            display: flex;
            align-items: center;

            img {
                filter: drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.3));
                margin-right: 1rem;
                width: 120px;
                height: 120px;
            }

            h1 {
                color: white;
                font-weight: 400;
            }

            text-decoration: none;
        }

        padding-top: 1rem;
        padding-bottom: 1rem;
        background-color: #5f246b;
        margin-bottom: 1rem;
    }

    h2 {
        color: #ba6bca;
    }

    img.shadow {
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.4);
    }

    img.round {
        border-radius: 6px;
    }

    img.middle {
        margin-top: 2rem;
        margin-left: auto;
        margin-right: auto;
        display: block;
        max-width: 100%;
    }

    .platformSwitch {
        background-color: #eee;
        padding: 0.5rem 1rem;
        display: inline-block;
        border-radius: 20px;
    }
}

a {
    color: rgb(0, 102, 255);
}

.downloads {
    border-top: 20px solid white;
    background-color: #eee;
    padding: 2.5em;

    h2 {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    img {
        width: 80px;
    }

    .description {
        text-align: center;
        margin-bottom: 1.5rem;
        font-weight: bold;
        color: #444;
        font-size: 90%;
    }

    > div {
        max-width: 48rem;
        line-height: 160%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .container > div {
        display: flex;
        flex-wrap: wrap;
        padding: 15px;
        overflow: hidden;
        padding-bottom: 2em;
    }

    .platform {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        padding: 20px 30px;
        color: black;
        transition: transform 0.3s, background-color 0.3s;
        min-width: 10em;
        border-radius: 10px;
        border: 1px solid #EEEEEE00;
    }

    .platform:hover {
        transform: scaleX(1.1) scaleY(1.1);
        background-color: white;
        border: 1px solid #eee;
    }

    .name {
        margin-top: 1em;
        font-weight: bold;
        font-size: 110%;
    }

    .detail {
        font-size: 85%;
        color: rgba(70, 70, 70, 1);
    }
}

.footer {
    background-color: #eee;
    padding: 2em;
    font-size: 14px;

    a {
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }

    .links {
        display: flex;
        flex-direction: column;
        margin-right: 1em;
    }

    .copyright {
        margin-top: 1em;
        border-top: 1px dashed #CCC;
        padding-top: 0.5em;
        text-align: end;
        display: block;
    }

    > div {
        max-width: 48rem;
        line-height: 160%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 2rem;
        padding-right: 2rem;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}
