@import "common.scss";

section.jumbotron {
    div.sticky {
        // height: 300px;
        background-color: rgb(66, 97, 122);
        position: sticky;
        @include small {
            position: static;
        }
        @include medium {
            position: static;
        }
        top: 0px;
        bottom: 0px;
        display: flex;
        flex-direction: column;
        // z-index: -1;

        > div {
            display: flex;
            flex-direction: column;
            max-width: 48rem;
            margin-left: auto;
            margin-right: auto;
            position: relative;
        }

        .screenshot_and_buttons {
            display: flex;
            flex-direction: row-reverse;
            margin-top: 3rem;
            height: 170px;

            @include medium {
                height: auto;
                flex-direction: column;
            }

            @include small {
                margin-top: 2rem;
            }
        }

        .screenshot-container-container {
            width: 480px;

            @include medium {
                display:none;
            }
        }

        .buttons_and_devices {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @include medium {
                align-items: center;
            }
        }

        .devices {
            margin-left: 1rem;
            margin-top: 1.5rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @include medium {
                display: none;
            }
        }

        div.logo_and_blurb {
            display: flex;
            flex-direction: row;
            margin-top: 3em;
            @include small {
                margin-top: 1em;
                flex-direction: column;
            }
            align-items: center;

            img {
                filter: drop-shadow(0px 0px 30px rgba(255, 255, 255, 0.5));
            }

            > img {
                width: 240px;
                height: 240px;
                margin-right: 1rem;

                @include medium {
                    width: 180px;
                    height: 180px;
                    margin-left: 1rem;
                }

                @include small {
                    margin-bottom: 1rem;
                }
            }

            > div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-right: 2rem;
                margin-left: 2rem;
                @include medium {
                    margin-left: 1rem;
                    margin-right: 1rem;
                }

                @include small {
                    align-items: center;
                }
            }

            h1 {
                color: white;
                font-weight: 800;
                font-style: italic;
                font-size: 90px;
                margin: 0px;

                @include medium {
                    font-size: 80px;
                }

                @include small {
                    font-size: 70px;
                }
            }

            .blurb {
                line-height: 150%;
                font-size: 130%;
                font-style: oblique;
                color: white;
                max-width: 24rem;
                font-weight: 200;

                @include medium {
                    font-size: 120%;
                    max-width: 20rem;
                }

                @include small {
                    font-size: 115%;
                    max-width: 18rem;
                }

                em {
                    font-weight: bold;
                }
            }
        }
    }

    > div.padding {
        height: 200px;
        background-color: #e0e0e0;
        @include medium {
            height: 0px;
        }
    }

    //
    //
    //

    $download_color: #ff9a04;

    .simple-screenshot {
        display:none;
        margin-bottom: 2rem;
        margin-top: 1rem;
        padding-left: 1em;
        padding-right: 1em;

        img {
            border-radius: 14px;
            border: 2px solid rgba(255, 255, 255, 1);
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
            object-fit: contain;
            max-width: 100%;
            box-sizing: border-box;
        }

        @include medium {
            display: inline-block;
        }
    }

    .buttons {
        margin-left: 2rem;
        display: flex;
        flex-direction: column;
        min-height: 170px;

        @include medium {
            flex-direction: row;
            min-height: auto;
            margin-left: 0rem;
        }

        @include small {
            flex-direction: column;
        }

        > div {
            margin-bottom: 1.3rem;
            display: inline-block;
            position: relative;
            padding: 0px;

            @include medium {
                width: 180px;
            }
        }

        > div:first-of-type {
            @include medium {
                margin-right: 2rem;
            }
            @include small {
                margin-right: 0rem;
            }
        }

        > div > a {
            display: block;
            padding: 0.5em 1em;
            background-color: $download_color;
            color: white;
            font-size: 150%;
            border-radius: 7px;
            transition: background-color 0.3s, transform 0.3s;
            text-decoration: none;
            text-align: center;
            z-index: 100000;
        }

        > div .popup {
            position: absolute;
            font-size: 85%;
            right: 100%;
            top: 50%;
            transform: translate(0, -50%);
            padding-right: 25px;
            pointer-events: none;
            z-index: 10000;
            line-height: 140%;

            > div {
                // background-color: #444;
                transition: opacity 0.5s, transform 0.5s;
                transform: translate(-10px, 0px);
                opacity: 0;

                color: #000;
                // border: 4px solid rgba(255, 255, 255, 0.836);
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
                border-radius: 11px;

                display: flex;
                flex-direction: column;
                align-items: stretch;

                overflow: hidden;

                > div {
                    background-color: rgba(255, 255, 255, 1);
                }
            }

            a {
                font-weight: bold;
            }
        }

        > div:hover .popup {
            pointer-events: all;
        }

        > div > a:hover {
            background-color: mix($download_color, red, 75%);
            transform: scaleX(1.1) scaleY(1.1);
        }

        > div:hover .popup > div {
            opacity: 1;
            transform: none !important;
        }

        > div.download .popup {
            right: unset;
            top: unset;
            bottom: 100%;
            left: 50%;
            transform: translate(-50%, 0);
            padding-right: 0px;
            padding-bottom: 20px;

            > div {
                transform: translate(0px, -10px);
            }
        }

        > div.buy .popup {

            @include medium {
                right: unset;
                top: unset;
                bottom: 100%;
                left: 50%;
                transform: translate(-50%, 0);
                padding-right: 0px;
                padding-bottom: 20px;

                > div {
                    transform: translate(0px, -10px);
                }
            }

            @include small {
                right: unset;
                top: unset;
                top: 100%;
                left: 50%;
                transform: translate(-50%, 0);
                padding-right: 0px;
                padding-top: 20px;

                > div {
                    transform: translate(0px, 10px);
                }
            }
        }

        .buy .popup > div > div {
            display: flex;
            flex-direction: row;
            align-items: stretch;

            @include medium{
                flex-direction: column;
            }
        }

        .buy .price {
            border-left: 1px dotted rgba(0, 0, 0, 0.3);
            // background-color: rgba(255, 255,255, 0.1);
            padding-left: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-weight: bold;
            font-size: 300%;
            padding-right: 1rem;
            color: rgb(96, 153, 218);

            @include medium {
                padding-right: 0rem;
                padding-left: 0rem;
                padding-bottom: 1.5rem;
                padding-top: 1.5rem;
                flex-direction: row;

                border-left: none;
                border-top: 1px dotted rgba(0, 0, 0, 0.3);
            }
        }

        .popup ul {
            list-style-type: none;
            padding-left: 10px;
            padding-right: 10px;

            li {
                white-space: nowrap;
                margin-bottom: 0.25rem;
                padding-left: 0.3rem;
            }

            li:last-child {
                margin-bottom: 0px;
            }
        }
    }
} // jumbotron
